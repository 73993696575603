// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "w_c1";
export var onlineMarketplaceCardBorderSection = "w_cL";
export var onlineMarketplaceCardRowIconsSection = "w_cR";
export var onlineMarketplaceClientQuotesSection = "w_c2";
export var onlineMarketplaceCycleDevSection = "w_cM";
export var onlineMarketplaceCycleDevSection__testimonial = "w_cN";
export var onlineMarketplaceDomainExpertiseSection = "w_cP";
export var onlineMarketplaceExceptionSection = "w_cQ";
export var onlineMarketplaceFeaturedCaseSection = "w_cS";
export var onlineMarketplaceFeaturesSection = "w_cV";
export var onlineMarketplaceFeaturesSection__box = "w_cW";
export var onlineMarketplaceRequirementSection = "w_cX";
export var onlineMarketplaceSuccessStoriesSection = "w_cZ";
export var onlineMarketplaceSuccessfulSection = "w_cT";
export var onlineMarketplaceTechStackSection = "w_cY";
export var onlineMarketplaceWhyHireSection = "w_c0";